.app {
    width: 350px;
    margin: 0 auto;
}

.scroller {
    margin: 0 auto;
    height: 1000px;
    width: auto;
    overflow: auto;
}

.item {
    margin: 30px 0;
}
